import { Link } from "react-router-dom";
import "./index.scss";

const Home = (): JSX.Element => {
  return (
    <>
      <div className="home">
        <div className="header">
          <div className="container">
            <div className="logo">
              <img src={require('../../assets/img/logo.png').default} alt="" />
            </div>
            <div className="space"></div>
            <ul className="nav">
              <li className="nav-item">
                <Link to={'privacy'}>Privacy Policy</Link>
              </li>
              <li className="nav-item">
                <Link to={'terms'}>Terms of Service</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="body">
          <div className="container">
            <div className="logo">
              <img src={require('../../assets/img/pic_logo.png').default} alt="" />
            </div>
            <p className="paragraph">
            Meet and connect with cool people from communities and beyond<br/>
            Express and build your social influence with fantastic lenses and stickers
            </p>
            <div className="download">
              <p>Coming soon on App Store and Google Play...</p>
              <div className="btn">
                <div className="btn_appstore item">
                  <img src={require('../../assets/img/appstore.png').default} alt="" />
                </div>
                <div className="btn_google item">
                  <img src={require('../../assets/img/googleplay.png').default} alt="" />
                </div>
              </div>
            </div>
            <div className="email">
              <div className="href">
                <div className="img">
                  <img src={require('../../assets/img/email.png').default} alt="" />
                </div>
                <p>nxgentech2022@gmail.com</p>
              </div>
              <div className="href">
                <div className="img">
                  <img src={require('../../assets/img/phone.png').default} alt="" />
                </div>
                <p>+6585046391</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
