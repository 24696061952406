import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Terms from './pages/Terms/index';
import Privacy from './pages/Privacy/index';
import PrivacyAndroid from './pages/Privacy-android'
import TermsAndroid from './pages/Terms-android'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/android/privacy" element={<PrivacyAndroid />} />
        <Route path="/android/terms" element={<TermsAndroid />} />
      </Routes>
      <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
    </Router>
  );
}

export default App;
